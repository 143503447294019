import React from "react";
import { Display1, Headline2 } from "components/common/typography/Typography";
import { Page } from "components/page/Page";

const NotFound = () => (
  <Page>
    <div className="pt-xxxl flex flex-col gap-xs px-xxxl lg:px-xl md:px-md">
      {/* eslint-disable-next-line react/jsx-no-undef */}
      <Display1>404</Display1>

      <Headline2>Something went wrong!</Headline2>
    </div>
  </Page>
);

export default NotFound;
